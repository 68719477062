import { Modal, Popconfirm, Progress, Select, Spin, message } from 'antd'
import { useEffect, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import { EmailOutlined, PhoneOutlined } from '@mui/icons-material'
import poste from '../../assets/poste.png'
import client from '../../assets/client.png'
import contract from '../../assets/contract.png'
import remuneration from '../../assets/remuneration.png'
import {
  CheckCircleTwoTone,
  CheckOutlined,
  FilePdfOutlined,
  MailOutlined,
} from '@ant-design/icons'
import _ from 'lodash'

const ModalDetail = ({
  setOrder,
  setOpenModal,
  openCommandeModal,
  setOpenCommandeModal,
  selectedCommande,
  setSelectedCommande,
}) => {
  const [isCancelOrder, setIsCancelOrder] = useState(false)
  const [canceller, setCanceller] = useState('')
  const [cancelReason, setCancelReason] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  const [percent, setPercent] = useState(0)
  const [isCreatingContract, setIsCreatingContract] = useState(false)

  const setPercentCompleted = (commande) => {
    if (commande.confirmedCandidates?.length) setPercent(80)
    if (commande.submittedToClient?.length) setPercent(60)
    if (commande.acceptedCandidates?.length) setPercent(40)
    if (commande.candidates?.length) setPercent(20)
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const handleDownloadPdf = (candidate) => {
    axiosInstance
      .get(`cv/${candidate._id}`)
      .then((res) => {
        const link = document.createElement('a')
        link.href = `data:application/pdf;base64,${res.data.data}`
        link.download = `${candidate.firstname}-${candidate.lastname}.pdf`
        // Simulate a click event to trigger the download
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    // Generate a temporary anchor element and set the PDF file as its href
  }

  const finishOrder = () => {
    axiosInstance
      .patch(`order/${selectedCommande._id}`, {
        status: 'terminee',
      })
      .then(() => {
        message.success('Commande terminee!')
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
  }

  const cancelOrder = () => {
    if (cancelReason.length && canceller.length) {
      axiosInstance
        .patch(`order/${selectedCommande._id}`, {
          status: 'annulée',
          cancelReason,
          cancelledBy: canceller,
        })
        .then(() => {
          message.success('Commande annulée!')
          setCancelReason('')
          setCanceller('')
          setIsCancelOrder(false)
          setOpenCommandeModal(false)
        })
        .catch((e) => console.log(e))
    }
  }

  const submitToClient = (candidate) => {
    axiosInstance
      .get('/order/submit-to-client', {
        params: {
          candidateId: candidate._id,
          orderId: selectedCommande._id,
        },
      })
      .then((res) => {
        const newCommande = res.data
        const contact = newCommande.contact.split(' ')
        const phone = contact.pop()
        const email = contact.pop()
        const name = contact.join(' ')
        setSelectedCommande({
          ...newCommande,
          contactData: { email, phone, name },
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleOk = (candidate) => {
    axiosInstance
      .get('/order/confirm-candidate', {
        params: {
          candidateId: candidate._id,
          orderId: selectedCommande._id,
        },
      })
      .then((res) => {
        const newCommande = res.data
        const contact = newCommande.contact.split(' ')
        const phone = contact.pop()
        const email = contact.pop()
        const name = contact.join(' ')
        setSelectedCommande({
          ...newCommande,
          contactData: { email, phone, name },
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const refuseCandidate = (candidate) => {
    axiosInstance
      .get('/order/refuse-candidate', {
        params: {
          candidateId: candidate._id,
          orderId: selectedCommande._id,
        },
      })
      .then((res) => {
        const newCommande = res.data
        const contact = newCommande.contact.split(' ')
        const phone = contact.pop()
        const email = contact.pop()
        const name = contact.join(' ')
        setSelectedCommande({
          ...newCommande,
          contactData: { email, phone, name },
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const createContract = (order, candidate) => {
    setIsCreatingContract(true)
    if (order.typeContrat !== 'Vacation') {
      axiosInstance
        .get(
          `order/create-contract?orderId=${order._id}&candidateId=${candidate._id}`,
        )
        .then((res) => {
          setIsCreatingContract(false)
          setSelectedCommande(res.data)
          message.success('Contrat créé !')
        })
        .catch((e) => {
          setIsCreatingContract(false)
          console.log(e)
          message.error("Une erreur s'est produite !")
        })
    } else {
      console.log(order.startDate)
      axiosInstance
        .post(`bill`, {
          facturation: order.facturation,
          client: order.client._id,
          candidate: candidate._id,
          qualification: order.qualification,
          startDate: order.startDate,
          endDate: order.endDate,
          order: order._id,
        })
        .then((res) => {
          setIsCreatingContract(false)
          console.log(res)
          //setSelectedCommande(res.data)
          message.success('Contrat et facture provisoire créés 😏!')
        })
        .catch((e) => {
          setIsCreatingContract(false)
          console.log(e)
          message.error("Une erreur s'est produite !")
        })
    }
  }

  const resendEmail = (commande, candidate) => {
    axiosInstance
      .get('/order/resend-email', {
        params: { orderId: commande._id, candidateId: candidate._id },
      })
      .then((res) => {
        message.success('Message envoyé')
      })
      .catch((e) => console.log(e))
  }

  const formatJsHour = (date) => {
    const newDate = new Date(date)
    const hours = String(newDate.getHours()).padStart(2, '0')
    const minutes = String(newDate.getMinutes()).padStart(2, '0') // Months are 0-based, so we add 1

    return `${hours}:${minutes}`
  }

  const sendEmail = (commande, candidate) => {
    const candidates = selectedCommande.candidates.filter(
      (c) => c._id !== candidate._id,
    )
    const contactedCandidates = [
      candidate,
      ...selectedCommande.contactedCandidates,
    ]
    axiosInstance
      .get('/order/email', {
        params: { orderId: commande._id, candidateId: candidate._id },
      })
      .then((res) => {
        message.success('Message envoyé')
        setSelectedCommande({
          ...selectedCommande,
          candidates,
          contactedCandidates,
        })
      })
      .catch((e) => console.log(e))
  }

  const confirmCandidate = (candidate) => {}

  const duplicateOrder = (isUpdate) => {
    setIsUpdate(isUpdate)
    console.log(selectedCommande)
    setOrder(selectedCommande)
    setOpenCommandeModal(false)
    setOpenModal(true)
  }

  return (
    <div>
      {isCancelOrder && (
        <Modal
          open={isCancelOrder}
          footer={null}
          onCancel={() => {
            setIsCancelOrder((bool) => false)

            console.log(isCancelOrder)
          }}
        >
          <div className="h-96 flex flex-col items-center justify-center space-y-6">
            <div className="w-4/5 flex items-center justify-center">
              <Select
                className="w-2/4  my-2 ring-0"
                showSearch="true"
                onChange={(e) => setCanceller(e)}
                value={canceller.length ? canceller : null}
                optionFilterProp="children"
                placeholder="Annulée par"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: 'le client',
                    label: 'le client',
                    key: 'le client',
                  },
                  {
                    value: "l'agence",
                    label: "l'agence",
                    key: "l'agence",
                  },
                ]}
              />
            </div>

            <div className="w-4/5 flex items-center justify-center">
              <Select
                className="w-2/4  my-2 ring-0"
                showSearch="true"
                value={cancelReason.length ? cancelReason : null}
                onChange={(e) => setCancelReason(e)}
                optionFilterProp="children"
                placeholder="Raison de l'annulation"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: 'concurrence',
                    label: 'concurrence',
                    key: 'concurrence',
                  },
                  {
                    value: 'plus de besoin',
                    label: 'plus de besoin',
                    key: 'plus de besoin',
                  },
                  {
                    value: 'trop cher',
                    label: 'trop cher',
                    key: 'trop cher',
                  },
                  {
                    value: 'ne repond plus',
                    label: 'ne repond plus',
                    key: 'ne repond plus',
                  },
                  {
                    value: 'pas de candidat à proposer',
                    label: 'pas de candidat à proposer',
                    key: 'pas de candidat à proposer',
                  },
                  {
                    value: 'atradius refusé',
                    label: 'atradius refusé',
                    key: 'atradius refusé',
                  },
                ]}
              />
            </div>

            <button
              onClick={cancelOrder}
              className="w-2/4 rounded-md p-2 bg-mountain-500 text-white"
            >
              Annuler
            </button>
          </div>
        </Modal>
      )}
      <Modal
        className="w-11/12"
        open={openCommandeModal}
        footer={null}
        onCancel={() => {
          setIsCancelOrder(false)
          setOpenCommandeModal(false)
        }}
      >
        <div className="mt-10">
          <p className="font-bold uppercase text-center text-4xl mb-6">
            {selectedCommande.typeContrat}
          </p>
          {selectedCommande.recruiter && (
            <p className="capitalize text-center text-xl font-semibold">
              Recruteur : <span>{selectedCommande?.recruiter?.firstname}</span>{' '}
              <span>{selectedCommande?.recruiter?.lastname}</span>
            </p>
          )}
          <p className="flex items-center justify-center h-12 mb-6">
            {isCreatingContract && <Spin />}
          </p>
          <div className="flex items-stretch space-x-2">
            <div className="flex flex-col h-60 grow">
              <div className="text-center flex items-center justify-center space-x-2  bg-orange-200 text-orange-500 rounded-t-lg p-2">
                <img src={client} className="w-6" />
                <p className="font-bold">Client</p>
              </div>
              <div className="flex flex-col items-start p-2 grow border border-gray-200 rounded-b-md bg-orange-50">
                <p>
                  <span className="font-semibold">Client : </span>
                  {selectedCommande?.client?.companyName}
                </p>
                <p>
                  <span className="font-semibold">Contact : </span>
                  {selectedCommande?.contactData?.name}
                </p>
                <p className="flex items-center space-x-2">
                  <PhoneOutlined />
                  <span className="font-semibold"></span>
                  {selectedCommande?.contactData?.phone}
                </p>
                <p className="flex items-center space-x-2">
                  <EmailOutlined />
                  <span className="font-semibold"></span>
                  {selectedCommande?.contactData?.email}
                </p>
              </div>
            </div>

            <div className="flex flex-col h-60 grow">
              <div className="text-center flex items-center justify-center space-x-2  bg-green-200 text-green-500 rounded-t-lg p-2">
                <img src={contract} className="w-6" />
                <p className="font-bold">Contrat</p>
              </div>

              <div className="flex flex-col items-start grow p-2 border border-gray-200 rounded-b-md bg-green-50">
                <p>
                  <span className="font-semibold">Type du contrat :</span>{' '}
                  {selectedCommande?.typeContrat}
                </p>
                {selectedCommande.typeContrat === 'Interim' && (
                  <div>
                    <p>
                      <span className="font-semibold">Motif : </span>
                      {selectedCommande?.motif}
                    </p>
                    <p>
                      <span className="font-semibold">Justificatif : </span>
                      {selectedCommande?.justificatif}
                    </p>
                  </div>
                )}
                {selectedCommande.typeContrat === 'CDI' && (
                  <p>
                    <span className="font-semibold">Date de debut : </span>
                    {formatJsDate(selectedCommande?.startDate)}{' '}
                  </p>
                )}

                {selectedCommande.typeContrat !== 'CDI' && (
                  <p>
                    <span className="font-semibold">Date : </span>
                    {formatJsDate(selectedCommande?.startDate)} à{' '}
                    {formatJsDate(selectedCommande?.endDate)}{' '}
                  </p>
                )}

                {selectedCommande?.startHour.length !== 5 && (
                  <p>
                    <span className="font-semibold">Horaire : </span>
                    {formatJsHour(selectedCommande?.startHour)} à{' '}
                    {formatJsHour(selectedCommande?.endHour)}{' '}
                  </p>
                )}
                {selectedCommande?.startHour.length === 5 && (
                  <p>
                    <span className="font-semibold">Horaire : </span>
                    {selectedCommande?.startHour} à {selectedCommande?.endHour}{' '}
                  </p>
                )}
                <p>
                  <span className="font-semibold">Jours travaillés : </span>{' '}
                  {selectedCommande.workedDays?.join(', ')}
                </p>
                {selectedCommande.typeContrat === 'Interim' && (
                  <p>
                    <span className="font-semibold">EPI : </span>
                    {selectedCommande?.epis?.join(' , ')}{' '}
                    <span className="font-semibold ">fourni par : </span>{' '}
                    {selectedCommande?.epiProvider}
                  </p>
                )}
                <p>
                  <span className="font-semibold">Tâches : </span>
                  {selectedCommande?.task}
                </p>
                {/* <div>{comment.comment.split('\n').map((comm)=> {
                return <p>{comm}</p>
              })}</div> */}
              </div>
            </div>

            <div className="flex flex-col h-60 grow">
              <div className="text-center flex items-center justify-center space-x-2  bg-purple-200 text-purple-500 rounded-t-lg p-2">
                <img src={poste} className="w-6" />
                <p className="font-bold">Poste</p>
              </div>
              <div className="flex flex-col items-start grow border p-2 border-gray-200 rounded-b-md bg-purple-50">
                <p>
                  <span className="font-semibold">Lieu de la mission : </span>
                  {selectedCommande?.address}, {selectedCommande.postCode}{' '}
                  {selectedCommande.town}{' '}
                </p>
                <p>
                  <span className="font-semibold">Poste :</span>{' '}
                  {selectedCommande?.qualification}{' '}
                </p>
                <p>
                  <span className="font-semibold">Quantité : </span>
                  {selectedCommande?.quantity}{' '}
                </p>
                <p>
                  <span className="font-semibold">Vehiculé : </span>
                  {selectedCommande?.isVehicule && 'Oui'}{' '}
                  {!selectedCommande.isVehicule && 'Non'}
                </p>
              </div>
            </div>

            <div className="flex flex-col h-60 grow">
              <div className="text-center flex items-center justify-center space-x-2  bg-ultramarine-200 text-ultramarine-500 rounded-t-lg p-2">
                <img src={remuneration} className="w-6" />
                <p className="font-bold">Rémuneration</p>
              </div>
              <div className="flex flex-col items-start grow border p-2 border-gray-200 rounded-md bg-ultramarine-50">
                <p>
                  <span className="font-semibold">Taux horaire :</span>{' '}
                  {selectedCommande?.hourlyRate}
                </p>
                <p>
                  <span className="font-semibold">Primes/indemnités :</span>{' '}
                  {selectedCommande?.primes
                    ?.map((prime) => prime.prime)
                    .join(' , ')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2 mt-4">
          <div className="grow border flex flex-col border-gray-400 h-96 w-1/4 rounded-md overflow-scroll">
            <p className="border-b border-gray-200 text-blue-400 p-2 mb-6 font-semibold pt-4 rounded-md text-center bg-blue-50">
              Candidats disponibles
            </p>
            {selectedCommande.candidates?.map((candidate, idx) => {
              return (
                <div
                  id={candidate._id}
                  className="flex cursor-pointer text-sm items-center justify-between space-x-2 px-2"
                  key={idx}
                >
                  <div
                    onClick={() => handleDownloadPdf(candidate)}
                    className="flex items-center space-x-1"
                  >
                    <p className="text-sm capitalize">{candidate.firstname}</p>
                    <p className="text-sm capitalize">{candidate.lastname}</p>
                  </div>

                  <p className="flex items-center space-x-2">
                    <MailOutlined
                      onClick={() => sendEmail(selectedCommande, candidate)}
                    />
                  </p>
                </div>
              )
            })}
            {selectedCommande.candidates?.length === 0 && (
              <div className="flex items-center justify-center grow">
                <p className="text-sm">Aucun candidat</p>
              </div>
            )}
          </div>
          <div
            id="contactedCandidat"
            className="grow border border-gray-400 h-96 w-1/4 rounded-md overflow-scroll"
          >
            <p className="border-b border-gray-200 text-blue-400 p-2 pt-4 mb-6 font-semibold rounded-md text-center bg-blue-50">
              Candidats contactés
            </p>
            {selectedCommande.contactedCandidates?.map((candidate, idx) => {
              return (
                <div
                  id={candidate._id}
                  className="flex cursor-pointer text-sm items-center justify-between space-x-2 px-2"
                  key={idx}
                >
                  <div className="flex items-center space-x-1">
                    <p className=" capitalize">{candidate.firstname}</p>
                    <p className=" capitalize">{candidate.lastname}</p>
                  </div>
                  {selectedCommande.refusedCandidates.includes(
                    candidate._id,
                  ) && (
                    <p className=" text-lg text-red-500 flex items-center space-x-2">
                      X
                    </p>
                  )}

                  {!selectedCommande.refusedCandidates.includes(
                    candidate._id,
                  ) && (
                    <p className=" flex items-center space-x-2">
                      <MailOutlined
                        onClick={() => resendEmail(selectedCommande, candidate)}
                      />
                    </p>
                  )}
                </div>
              )
            })}
          </div>
          <div className="grow border border-gray-400 h-96 w-1/4 rounded-md overflow-scroll">
            <p className="border-b border-gray-200 text-blue-400  p-2 pt-4 rounded-md mb-6 font-semibold text-center bg-blue-50">
              D'accord pour la mission
            </p>
            {selectedCommande.acceptedCandidates?.map((candidate, idx) => {
              return (
                <div
                  className="flex text-sm items-center justify-between px-2"
                  key={idx}
                >
                  <div className="flex items-center space-x-2">
                    <p className="capitalize">{candidate.firstname}</p>
                    <p className=" capitalize">{candidate.lastname}</p>
                  </div>
                  <Popconfirm
                    title="Proposer au client"
                    description={`Voulez vous proposer ${candidate.firstname} ${candidate.lastname} au client ?`}
                    onConfirm={() => submitToClient(candidate)}
                    onCancel={() => refuseCandidate(candidate)}
                    okText="Oui"
                    cancelText="Non"
                    okButtonProps={{ className: 'bg-blue-600 text-white' }}
                  >
                    <CheckCircleTwoTone
                      onClick={() => confirmCandidate(candidate)}
                      className="text-xl"
                      twoToneColor="#52c41a"
                    />
                  </Popconfirm>
                </div>
              )
            })}
          </div>

          <div className="grow border border-gray-400 h-96 w-1/4 rounded-md overflow-scroll">
            <p className="border-b border-gray-200 text-blue-400  p-2 pt-4 rounded-md mb-6 font-semibold text-center bg-blue-50">
              Refusés par l'agence
            </p>
            {selectedCommande.refusedByAgence?.map((candidate, idx) => {
              return (
                <div
                  className="flex text-sm items-center justify-between px-2"
                  key={idx}
                >
                  <div className="flex items-center space-x-1">
                    <p className="capitalize">{candidate.firstname}</p>
                    <p className=" capitalize">{candidate.lastname}</p>
                  </div>
                  <p className="  text-red-500 flex items-center space-x-2">
                    X
                  </p>
                </div>
              )
            })}
          </div>

          <div
            id="proposedCandidat"
            className="grow border border-gray-400 h-96 w-1/4 rounded-md overflow-scroll"
          >
            <p className="border-b border-gray-200 text-blue-400 p-2 pt-4 mb-6 font-semibold rounded-md text-center bg-blue-50">
              Proposés au client
            </p>
            {selectedCommande?.submittedToClient?.map((candidate, idx) => {
              return (
                <div
                  className="flex cursor-pointer text-sm items-center justify-between space-x-2 px-2"
                  key={idx}
                >
                  <div className="flex items-center space-x-1">
                    <p className=" capitalize">{candidate.firstname}</p>
                    <p className=" capitalize">{candidate.lastname}</p>
                  </div>
                  <Popconfirm
                    title="Confirmer le candidat"
                    description={`Voulez vous confirmer ${candidate.firstname} ${candidate.lastname} ?`}
                    onConfirm={() => handleOk(candidate)}
                    okButtonProps={{ className: 'bg-blue-600 text-white' }}
                    okText="Oui"
                    cancelText="Non"
                  >
                    <CheckOutlined
                      className="text-green-400"
                      twoToneColor="#52c41a"
                    />
                  </Popconfirm>
                </div>
              )
            })}
          </div>
          <div className="grow border border-gray-400 h-96 w-1/4 rounded-md overflow-scroll">
            <p className="border-b border-gray-200 mb-6 text-blue-400 p-2 font-semibold pt-4 rounded-md text-center bg-blue-50">
              Candidats confirmés
            </p>
            {selectedCommande.confirmedCandidates?.map((candidate, idx) => {
              return (
                <div
                  className="flex items-center justify-between space-x-2 px-2"
                  key={idx}
                >
                  <div className="flex items-center text-sm space-x-2">
                    <p className=" capitalize">{candidate.firstname}</p>
                    <p className="capitalize">{candidate.lastname}</p>
                  </div>
                  {!selectedCommande.contractedCandidates.includes(
                    candidate._id,
                  ) && (
                    <Popconfirm
                      title="Confirmer le candidat"
                      description={`Créer un contrat de prestation et une facture pour ${candidate.firstname} ${candidate.lastname} ?`}
                      onConfirm={() =>
                        createContract(selectedCommande, candidate)
                      }
                      okText="Oui"
                      okButtonProps={{ className: 'bg-blue-600 text-white' }}
                      cancelText="Non"
                    >
                      <FilePdfOutlined
                        className="text-sm"
                        twoToneColor="#52c41a"
                      />
                    </Popconfirm>
                  )}

                  {selectedCommande.typeContrat === 'Interim' &&
                    selectedCommande.contractedCandidates.includes(
                      candidate._id,
                    ) && (
                      <CheckCircleTwoTone
                        className="text-xl"
                        twoToneColor="#52c41a"
                      />
                    )}
                </div>
              )
            })}
          </div>
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6">
          <button
            onClick={() => duplicateOrder(false)}
            className="bg-blue-50 p-2 border border-blue-300 font-semibold text-blue-400 rounded-md"
          >
            Dupliquer
          </button>
          <button
            onClick={() => duplicateOrder(true)}
            className="bg-green-50 p-2 border border-green-300 font-semibold text-green-400 rounded-md"
          >
            Modifier
          </button>
          <button
            onClick={() => setIsCancelOrder(true)}
            className="bg-orange-50 p-2 border border-orange-300 font-semibold text-orange-400 rounded-md"
          >
            Annuler
          </button>
          <button
            onClick={() => finishOrder()}
            className="bg-orange-50 p-2 border border-orange-300 font-semibold text-orange-400 rounded-md"
          >
            Terminer
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default ModalDetail
