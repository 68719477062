import axiosInstance from "../../../axios/axiosInstance"
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { AddCircleOutlineOutlined, CalendarMonth } from '@mui/icons-material'
import { useState,useEffect } from "react";
import {message} from "antd";

const CandidatPay = ({user,setUser,candidates,setCandidates,isOpen,setIsOpen}) => {


    const [pays,setPays] = useState([]);

    useEffect(()=> {
        loadPay();
    },[])

    const loadPay = () => {
        if(!user.pld)return;
        axiosInstance
          .get(`payslip/candidate/${user.pld}`)
          .then((res) => {
            setPays(res.data)
            console.log(res.data);
          })
          .catch((e) => message.error("Une erreur s'est produite !"))
      }

    const handleDownloadPdf = (pay) => {
        axiosInstance
          .get(`payslip/${pay._id}`)
          .then((res) => {
            const binaryData = Uint8Array.from(window.atob(res.data.data), (char) =>
              char.charCodeAt(0),
            )
            const pdfBlob = new Blob([binaryData], { type: 'application/pdf' })
            const pdfUrl = URL.createObjectURL(pdfBlob)
            window.open(pdfUrl, '_blank')
          })
          .catch((e) => console.log(e))
      }


    return (
    <div>

        <div className="flex items-center justify-between  mb-6 mt-10 text-md">
            <p className="w-1/3">AGENCE</p>
            <p className="w-1/3">INTERIMAIRE</p>
            <p className="w-1/3">MOIS DE PAIE</p>
          </div>

<div className="flex flex-col space-y-4">
{pays.map((pay, idx) => {
  return (
    <div
      key={idx}
      onClick={(e) => handleDownloadPdf(pay)}
      className=" flex py-6  items-center drop-shadow-2xl text-xs  justify-between  bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
    >
      <div className="w-1/3">
        <p className="font-bold">{pay.agency.name}</p>
        <p className="text-gray-500 capitalize">{pay.agency.town.toLowerCase()}</p>
      </div>

      <div className="w-1/3 flex items-center space-x-2">
        <div>
          <p>
            <AccountBoxIcon className="text-3xl text-gray-300" />
          </p>
        </div>
        <div className="text-xs">
          <p className="font-bold">{pay.candidateName}</p>
          <p className="text-gray-500">{pay.candidatePld}</p>
        </div>
      </div>

      <div className="w-1/3 flex items-center space-x-2">
        <div>
          <p>
            <CalendarMonth className="text-3xl text-gray-300" />
          </p>
        </div>
        <div className="text-xs">
          <p className="font-bold text-center">{pay.date}</p>
        </div>
      </div>
    </div>
  )
})}
</div>
</div>
    )

}

export default CandidatPay;