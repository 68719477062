import { useEffect, useState, useRef } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Input, Modal, message } from 'antd'
import { Puff } from 'react-loader-spinner'
import CandidatModal from './CandidatModal'
import ClientModal from '../Client/ClientModal'
import html2canvas from 'html2canvas'

const Planning = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenClient, setIsOpenClient] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedContract, setSelectedContract] = useState(null)
  const [selectedClient, setSelectedClient] = useState(null)
  const [days, setDays] = useState([])
  const [daysOfMonth, setDaysOfMonth] = useState([])
  const [weekNumber, setWeekNumber] = useState('')
  const [monthNumber, setMonthNumber] = useState(0)
  const [sDate, setSDate] = useState(null)
  const [eDate, setEDate] = useState(null)
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(null)
  const [lastDayOfMonth, setLastDayOfMonth] = useState(null)
  const [filter, setFilter] = useState('hebdo')
  const [isClient, setIsClient] = useState(false)
  const [loading, setLoading] = useState(false)
  const [contracts, setContracts] = useState([])
  const [originalContracts, setOriginalContracts] = useState([])
  const divRefs = useRef([])
  const menuRef = useRef(null)

  const selectUser = (user) => {
    setSelectedUser(user)
    setIsOpen(true)
  }

  const textDays = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ]

  const formatVacationContract = (vacationContracts, startDate, endDate) => {
    let contracts = []
    for (let contract of vacationContracts) {
      let daysDate = getWeekDays(startDate, endDate).map((day) => day.date)
      let dates = []
      console.log(daysDate)
      let idx = 0
      for (let date of daysDate) {
        if (contract.dates.map((d) => formatDate(d.dateDebut)).includes(date)) {
          dates.push({
            day: formatDate(contract.dates[idx].dateDebut),
            startHour: formatJsHour(contract.dates[idx].startHour),
            endHour: formatJsHour(contract.dates[idx].endHour),
          })
          idx += 1
        } else {
          dates.push({})
        }
      }
      contracts.push({
        firstname: contract.candidate.firstname,
        candidate: contract.candidate,
        lastname: contract.candidate.lastname,
        phone: contract.candidate.phone,
        email: contract.candidate.email,
        address: contract.address,
        status: contract.status,
        isDematUser: contract.isDemat,
        contractNumber: contract.contractNumber,
        city: contract.town,
        postCode: contract.postCode,
        qualification: contract.qualification,
        client: contract.client.companyName,
        dates,
        type: 'Vacation',
        planningSent: false,
        vehicule: contract.candidate.locomotions.join(' '),
      })
    }
    return contracts
  }

  const envoiPlanning = async (contract, index) => {
    if (menuRef.current && divRefs.current[index]) {
      const canvas1 = await html2canvas(menuRef.current)
      const canvas2 = await html2canvas(divRefs.current[index])

      // Create a new canvas with enough height to fit both divs
      const combinedCanvas = document.createElement('canvas')
      const context = combinedCanvas.getContext('2d')

      const width = Math.max(canvas1.width, canvas2.width)
      const height = canvas1.height + canvas2.height // Total height = height of both canvases combined

      // Set the size of the new combined canvas
      combinedCanvas.width = width
      combinedCanvas.height = height

      // Draw the first canvas (div1) at the top
      context.drawImage(canvas1, 0, 0)
      // Draw the second canvas (div2) just below the first one
      context.drawImage(canvas2, 0, canvas1.height)

      const base64String = combinedCanvas.toDataURL('image/png').split(',')[1]
      const planningDate = formatDateFull(sDate) + '-' + formatDateFull(eDate)
      axiosInstance
        .post('pld-contract/envoi-planning', {
          base64: base64String,
          candidateId: contract.candidate._id,
          planningDate,
        })
        .then((res) => {
          message.success('Planning envoyé')
        })
        .catch((e) => {
          console.log(e)
          message.error("Une erreur s'est produite! ")
        })
    }
  }

  const formatPldContract = (pldContracts, startDate, endDate) => {
    let contracts = []
    console.log(pldContracts);
    for (let contract of pldContracts) {
      let generatedDays = genererDates(
        contract._doc.DateDebut,
        contract._doc.DateFin,
      )
      let daysDate = getWeekDays(startDate, endDate).map((day) => day.date)
      let dates = []
      for (let day of daysDate) {
        if (generatedDays.includes(day)) {
          dates.push({
            day,
            startHour:
              contract._doc.HoraireAMDebut.length > 0
                ? formatHour(contract._doc.HoraireAMDebut)
                : formatHour(contract._doc.HorairePMDebut),
            endHour:
              contract._doc.HoraireAMFin.length > 0
                ? formatHour(contract._doc.HoraireAMFin)
                : formatHour(contract._doc.HorairePMFin),
          })
        } else {
          dates.push({})
        }
      }
      contracts.push({
        firstname: contract.candidate?.firstname,
        candidate: contract.candidate,
        lastname: contract.candidate?.lastname,
        phone: contract.candidate?.phone,
        email: contract.candidate?.email,
        status: '',
        address: contract.candidate?.address,
        isDematUser: contract._doc.isDematUser,
        isDematClient: contract._doc.isDematClient,
        EstNonLieu: contract._doc.EstNonLieu,
        EstNonDemarra: contract._doc.EstNonDemarre,
        contractNumber:
          contract._doc.NumeroContrat + '.' + contract._doc.NumeroAvenant,
        city: contract.candidate?.town,
        postCode: contract.candidate?.postCode,
        qualification: contract._doc.TxtQualification,
        client: contract._doc.RaisonSociale,
        dates,
        type: 'Interim',
        planningSent: false,
        vehicule: contract.candidate?.locomotions.join(' '),
      })
    }
    setContracts(contracts)
    return contracts
  }

  const selectClient = (user) => {
    setSelectedUser(user)
    setIsOpen(true)
  }

  const months = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aôut',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ]

  useEffect(() => {
    axiosInstance
      .get('contract/hhhhhhh')
      .then((res) => console.log(res))
      .catch((e) => console.log(e))

    axiosInstance
      .get('pld-contract/hhhhhh')
      .then((res) => console.log(res))
      .catch((e) => console.log(e))

    /*   axiosInstance
      .get('bill/ffff')
      .then((res) => console.log(res))
      .catch((e) => console.log(e))   */
    const s = new Date()
    const f = new Date()

    const firstDayOfMonth = new Date(f.getFullYear(), f.getMonth(), 1)
    const lastDayOfMonth = new Date(f.getFullYear(), f.getMonth() + 1, 0)

    const { startDate, endDate } = getStartAndEndDate(s)
    setFirstDayOfMonth(firstDayOfMonth)
    setLastDayOfMonth(lastDayOfMonth)
    getWeekNumber()
    setMonthNumber(s.getMonth())
    getWeekDays(startDate, endDate)
    getMonthDays(s.getYear(), s.getMonth() + 1)
    setSDate(startDate)
    setEDate(endDate)
    loadCombinedContracts(startDate, endDate)
  }, [])

  function getDatesBetween(startDate, endDate) {
    let dateArray = []
    let currentDate = new Date(startDate) // Cloner la date de début
    while (currentDate <= endDate) {
      // Formater la date au format DDMMYYYY
      const day = currentDate.getDate().toString().padStart(2, '0') // Jour avec zéro initial si nécessaire
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0') // Mois (indexé à 0 donc on ajoute 1)
      const year = currentDate.getFullYear().toString() // Année
      // Ajouter la date formatée au tableau
      dateArray.push(`${day}${month}${year}`)

      // Passer au jour suivant
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateArray
  }

  const formatHour = (hourString) => {
    return hourString.substring(0, 2) + 'h' + hourString.substring(2)
  }

  const formatJsHour = (d) => {
    let date = new Date(d)
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

    return `${hours}h${formattedMinutes}`
  }

  const switchToNextWeek = () => {
    setWeekNumber(weekNumber + 1)
    const startDate = new Date(sDate)
    startDate.setDate(sDate.getDate() + 7)
    const endDate = new Date(eDate)
    endDate.setDate(eDate.getDate() + 7)
    const newstartDateCalculation = new Date(startDate)
    getStartAndEndDate(newstartDateCalculation)
    setSDate(startDate)
    setEDate(endDate)
    getWeekDays(startDate, endDate)
    loadCombinedContracts(startDate, endDate)
  }

  const switchToNextMonth = () => {
    const nextMonthDate = new Date('2024', monthNumber + 1, 1) // First day of next month
    const firstDayOfNextMonth = new Date(
      nextMonthDate.getFullYear(),
      nextMonthDate.getMonth(),
      1,
    )
    const lastDayOfNextMonth = new Date(
      nextMonthDate.getFullYear(),
      nextMonthDate.getMonth() + 1,
      0,
    )
    loadCombinedContracts(firstDayOfNextMonth, lastDayOfNextMonth)
    console.log(monthNumber)
    if (monthNumber === 11) setMonthNumber(0)
    else setMonthNumber(monthNumber + 1)
  }

  const switchToPreviousMonth = () => {
    const previousMonthDate = new Date('2024', monthNumber - 1, 1) // First day of the previous month
    const firstDayOfPreviousMonth = new Date(
      previousMonthDate.getFullYear(),
      previousMonthDate.getMonth(),
      1,
    )
    const lastDayOfPreviousMonth = new Date(
      previousMonthDate.getFullYear(),
      previousMonthDate.getMonth() + 1,
      0,
    )

    loadCombinedContracts(firstDayOfPreviousMonth, lastDayOfPreviousMonth)

    console.log(monthNumber)

    if (monthNumber === 0) {
      setMonthNumber(11) // Go to December (index 11) if it's January
    } else {
      setMonthNumber(monthNumber - 1)
    }
  }

  const switchToPreviousWeek = () => {
    if (!weekNumber) return
    setWeekNumber(weekNumber - 1)
    const startDate = new Date(sDate)
    startDate.setDate(sDate.getDate() - 7)
    const endDate = new Date(eDate)
    endDate.setDate(eDate.getDate() - 7)
    const newstartDateCalculation = new Date(startDate)
    getStartAndEndDate(newstartDateCalculation)
    setSDate(startDate)
    setEDate(endDate)
    getWeekDays(startDate, endDate)
    loadCombinedContracts(startDate, endDate)
  }

  const getWeekNumber = () => {
    const date = new Date()
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
    const days = Math.round((date - firstDayOfYear) / (24 * 60 * 60 * 1000))
    const weekNumber = Math.ceil((days + firstDayOfYear.getDay() + 1) / 7)
    setWeekNumber(weekNumber)
  }

  const getStartAndEndDate = (sDate) => {
    const currentDay = sDate.getDay()
    const diff = sDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1) // Adjust when currentDay is Sunday

    // Calculate the first day of the week (Monday)
    const firstDay = new Date(sDate)
    firstDay.setDate(diff)
    firstDay.setHours(0, 0, 0, 0) // Set time to 00:00:00

    // Calculate the last day of the week (Sunday)
    const lastDay = new Date(firstDay)
    lastDay.setDate(firstDay.getDate() + 6) // Move 6 days ahead
    lastDay.setHours(23, 59, 59, 999) // Set time to 23:59:59

    return {
      startDate: firstDay, // Return the start of the week (Monday)
      endDate: lastDay, // Return the end of the week (Sunday)
    }
  }

  const getWeekDays = (firstDay, lastDay) => {
    const days = []
    const currentDate = new Date(firstDay)

    while (currentDate <= lastDay) {
      const dayName = getDayName(currentDate.getDay())
      const dayDate = formatDate(currentDate)

      days.push({ name: dayName, date: dayDate })

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1)
    }
    if (days.length === 7) setDays(days)
    else setDaysOfMonth(days)
    return days
  }

  const genererDates = (debut, fin) => {
    let dates = []

    // Conversion des chaînes de caractères en objets Date
    let dateDebut = new Date(
      debut.slice(0, 4),
      debut.slice(4, 6) - 1,
      debut.slice(6, 8),
    )
    let dateFin = new Date(
      fin.slice(0, 4),
      fin.slice(4, 6) - 1,
      fin.slice(6, 8),
    )

    // Boucle pour générer les dates entre le début et la fin
    while (dateDebut <= dateFin) {
      let jour = String(dateDebut.getDate()).padStart(2, '0')
      let mois = String(dateDebut.getMonth() + 1).padStart(2, '0')
      dates.push(`${jour}/${mois}`)

      // Ajoute un jour à la date
      dateDebut.setDate(dateDebut.getDate() + 1)
    }
    return dates
  }

  const genererDatesVacations = (dates) => {
    const formattedDates = []
    const newDates = dates.map((item) => item.dateDebut)
    for (let date of newDates) {
      const D = new Date(date)
      const day = String(D.getDate()).padStart(2, '0')
      const month = String(D.getMonth() + 1).padStart(2, '0') // getMonth() retourne 0-11

      formattedDates.push(`${day}/${month}`)
    }
    return formattedDates
  }

  const getMonthDays = (year, month) => {
    // Adjust the month (in JavaScript, months are 0-based, so 0 = January, 11 = December)
    const firstDay = new Date(year, month - 1, 1) // month - 1 because month is 0-indexed in Date()
    const lastDay = new Date(year, month, 0) // Getting the last day of the month

    const days = []
    const currentDate = new Date(firstDay)

    while (currentDate <= lastDay) {
      const dayName = getDayName(currentDate.getDay()) // Get the name of the day (e.g., Monday)
      const dayDate = formatDate(currentDate) // Format the date (e.g., "2023-09-15")

      days.push({ name: dayName, date: dayDate })

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1)
    }

    setDaysOfMonth(days) // Assuming setDaysOfMonth is a state update function (e.g., React or other)
  }

  const getDayName = (dayIndex) => {
    const daysOfWeek = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ]
    return daysOfWeek[dayIndex]
  }

  const formatDate = (d) => {
    let date = new Date(d)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    return `${day}/${month}`
  }

  const formatDateFull = (d) => {
    let date = new Date(d)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear())
    return `${day}/${month}/${year}`
  }

  const loadCombinedContracts = (startDate, endDate) => {
    setLoading(true)
    const dates = getDatesBetween(startDate, endDate)
    Promise.all([
      axiosInstance.post('contract/between', { dates }),
      axiosInstance.post('pld-contract/between', { dates }),
    ])
      .then(([vacationResponse, pldResponse]) => {
        const vacations = vacationResponse.data
        const pldContracts = pldResponse.data
        console.log(vacations)

        const formattedVacations = formatVacationContract(
          vacations,
          startDate,
          endDate,
        )
        const formattedPld = formatPldContract(pldContracts, startDate, endDate)

        const combinedContracts = [...formattedVacations, ...formattedPld]
        const newContracts = []
        for (let contract of combinedContracts) {
          const contractIdx = newContracts.findIndex(
            (ctr) => ctr.candidate._id === contract.candidate._id,
          )
          if (contractIdx === -1) {
            newContracts.push(contract)
          } else {
            const tableauFusionne = fusionnerTableaux(
              newContracts[contractIdx].dates,
              contract.dates,
            )
            newContracts[contractIdx] = {
              ...newContracts[contractIdx],
              dates: tableauFusionne,
            }
          }
        }
        newContracts.sort((a, b) => a.lastname.localeCompare(b.lastname))
        setContracts(newContracts)
        setOriginalContracts(newContracts)
      })
      .catch((e) => console.log(e)) // Handle any error
      .finally(() => setLoading(false)) // End loading
  }

  const filterSearch = (query) => {
    if (query.length === 0) {
      setContracts(originalContracts)
      return
    }
    const filteredContracts = filterArrayByWord(contracts, query)
    setContracts(filteredContracts)
  }

  function filterArrayByWord(arr, word) {
    return arr.filter(
      (item) =>
        item.candidate.firstname.toLowerCase().includes(word.toLowerCase()) ||
        item.candidate.lastname.toLowerCase().includes(word.toLowerCase()) ||
        item.client.toLowerCase().includes(word.toLowerCase()),
    )
  }

  const estObjetVide = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  // Fusionner les deux tableaux
  const fusionnerTableaux = (t1, t2) => {
    return t1.map((date, index) => {
      if (!estObjetVide(date)) {
        return date // Si le premier objet n'est pas vide, on le prend
      } else if (!estObjetVide(t2[index])) {
        return t2[index] // Sinon, on prend l'objet du deuxième tableau s'il n'est pas vide
      }
      return {} // Si les deux sont vides, on retourne un objet vide
    })
  }

  return (
    <>
      {isOpen && (
        <CandidatModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}

      {/* {isOpenClient  && <ClientModal
      selectedClient={selectedClient}
      setSelectedClient={setSelectedClient}
     />} */}
      <div
        style={{ width: 'calc(100vw - 400px)' }}
        className="mt-4 bg-[#F7F9FC]"
      >
        {loading && (
          <div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
            <Puff
              height="80"
              width="80"
              radius={1}
              color="#9061f9"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
        <div style={{ width: 'calc(100vw - 400px)' }}>
          <p className="text-lg mb-6 text-center font-semibold">LEGENDE</p>
          <div className="text-xs w-2/5 mx-auto mb-6 font-semibold flex justify-between">
            <div className="flex flex-col space-y-1">
              <p className="flex items-center space-x-2">
                <span className="h-6 w-10 bg-blue-200"></span>
                <span className="uppercase">Dématerialisé</span>
              </p>
              <p className="flex items-center space-x-2">
                <span className="h-6 w-10 bg-yellow-200"></span>
                <span className="uppercase">Non dématerialisé</span>
              </p>
            </div>

            <div className="flex flex-col space-y-1">
              <p className="flex items-center space-x-2">
                <span className="h-6 w-10 bg-red-200"></span>
                <span className="uppercase">Annulé</span>
              </p>

              <p className="flex items-center space-x-2">
                <span className="h-6 w-10 bg-purple-200"></span>
                <span className="uppercase">A pourvoir</span>
              </p>
            </div>
            <div className="flex flex-col space-y-1">
              <p className="flex items-center space-x-2">
                <span className="h-6 w-10 bg-green-200"></span>
                <span className="uppercase">Facturé</span>
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-2 justify-end">
            <Input
              onChange={(e) => filterSearch(e.target.value)}
              className={`w-80 rounded-md duration-500 border border-gray-200 focus:outline-none`}
              placeholder="Recherche"
              style={{ outline: 'none' }}
            />
            <button className="bg-purple-500 text-white p-2 rounded-md">
              Rechercher
            </button>
          </div>

          <div className="flex items-center mb-8 justify-between">
            <div className="flex hover:cursor-pointer items-center w-fit  border rounded-lg border-gray-300 bg-[#F7F9FC] ">
              <p
                onClick={() => setFilter('jour')}
                className={`${
                  filter === 'jour' && 'bg-purple-400'
                }  border-r p-2 border-r-gray-300`}
              >
                Jour
              </p>
              <p
                onClick={() => {
                  setFilter('hebdo')
                  const { startDate, endDate } = getStartAndEndDate(new Date())
                  loadCombinedContracts(startDate, endDate)
                }}
                className={`${
                  filter === 'hebdo' && 'bg-purple-400'
                }  border-r p-2 border-r-gray-300`}
              >
                Semaine
              </p>
              <p
                onClick={() => {
                  setFilter('mensuel')
                  loadCombinedContracts(firstDayOfMonth, lastDayOfMonth)
                }}
                className={`${
                  filter === 'mensuel' && 'bg-purple-400'
                }  border-r p-2 border-r-gray-300`}
              >
                Mois
              </p>
            </div>

            <div className="flex hover:cursor-pointer items-center w-fit  border rounded-lg border-gray-300 bg-[#F7F9FC] ">
              <p
                onClick={() => setIsClient(true)}
                className={`${
                  isClient && 'bg-purple-400'
                }  border-r p-2 border-r-gray-300`}
              >
                Client
              </p>

              <p
                onClick={() => setIsClient(false)}
                className={`${
                  !isClient && 'bg-purple-400'
                }  border-r p-2 border-r-gray-300`}
              >
                Interimaire
              </p>
            </div>

            {filter === 'hebdo' && (
              <div className="justify-end my-6  items-center  flex space-x-2">
                <LeftOutlined onClick={() => switchToPreviousWeek()} />
                <p className="text-xl flex flex-col items-center font-semibold">
                  <span>SEMAINE {weekNumber}</span>
                  <span className="text-sm">
                    {formatDate(sDate)} au {formatDate(eDate)}
                  </span>
                </p>
                <RightOutlined onClick={() => switchToNextWeek()} />
              </div>
            )}

            {filter === 'mensuel' && (
              <div className="justify-end my-6  items-center  flex space-x-2">
                <LeftOutlined onClick={() => switchToPreviousMonth()} />
                <p className="text-xl uppercase font-semibold">
                  {months[monthNumber]}
                </p>
                <RightOutlined onClick={() => switchToNextMonth()} />
              </div>
            )}
          </div>
        </div>
      </div>

      {!isClient && filter === 'hebdo' && (
        <div
          style={{ width: 'calc(100vw - 400px)' }}
          className="scrollbar-thin overflow-x-scroll bg-[#F7F9FC]"
        >
          <div
            style={{ width: 'calc(100vw - 400px)' }}
            className="flex font-semibold py-2 fixed bg-white"
            ref={menuRef}
          >
            <p className="w-1/12   text-center uppercase text-xs">Nom</p>
            <p className="w-1/12 text-center uppercase text-xs">Téléphone</p>
            <p className="w-1/12 text-center uppercase text-xs">Vehiculé</p>
            <p className="w-1/12 text-center uppercase text-xs">Ville</p>
            <p className="w-1/12 uppercase text-xs flex flex-col items-center">
              <span className="">Métier</span>
              <span className="">Jour/Nuit</span>
            </p>

            {days.map((day, idx) => {
              return (
                <div
                  key={idx}
                  className="w-1/12 flex items-center flex-col space-y-1"
                >
                  <p className="uppercase text-xs">{day.name}</p>
                  <p className="uppercase text-xs">{day.date}</p>
                </div>
              )
            })}
          </div>

          <div className="flex mt-10 flex-col font-semibold py-2">
            {contracts.map((contract, idx) => {
              return (
                <div
                  ref={(el) => (divRefs.current[idx] = el)}
                  className=" flex items-center"
                  key={idx}
                >
                  <p
                    className={`hover:cursor-pointer w-1/12  px-2  items-center flex flex-col font-semibold uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 `}
                  >
                    <span onClick={() => selectUser(contract.candidate)}>
                      {contract.lastname}
                    </span>
                    <span onClick={() => selectUser(contract.candidate)}>
                      {contract.firstname?.substring(0, 18)}
                    </span>
                    <span>
                      <button
                        onClick={() => envoiPlanning(contract, idx)}
                        className={`${
                          contract.candidate?.sentPlanning.includes(
                            formatDateFull(sDate) + '-' + formatDateFull(eDate),
                          )
                            ? 'bg-green-500'
                            : 'bg-purple-500'
                        } w-fit mx-auto mt-2 p-2  text-white rounded-md text-xs font-normal max-w-[160px]`}
                      >
                        Envoyer le planning{' '}
                      </button>
                    </span>
                  </p>

                  <p className="w-1/12 text-center uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 ">
                    <span>{contract.candidate?.phone}</span>
                  </p>
                  <p className="w-1/12 text-center  uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 ">
                    {contract.candidate?.locomotions.join(' ')}
                  </p>
                  <p className="w-1/12 flex flex-col uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 items-center">
                    <span>{contract.candidate?.postCode}</span>
                    <span>{contract.candidate?.town}</span>
                  </p>
                  <p className="w-1/12 text-center overflow-hidden uppercase text-xs border-y  border-r h-40 border-gray-200 pt-2 ">
                    {contract.qualification}
                  </p>
                  {contract.dates.map((date, idx2) => {
                    return (
                      <div
                        key={idx2}
                        className={` flex  w-1/12   flex-col items-center space-y-1 h-40 border-y border-r border-gray-200`}
                      >
                        {date.day && (
                          <div
                            className={`${
                              contract.status === 'Facturée' && 'bg-green-200'
                            } ${
                              (contract.EstNonLieu === 'O' ||
                                contract.EstNonDemarre === 'O') &&
                              'bg-red-200'
                            }  ${contract.isDematUser && 'bg-blue-200'} ${
                              !contract.isDematUser &&
                              contract.EstNonLieu !== 'O' &&
                              contract.EstNonDemarre !== 'O' &&
                              contract.status !== 'Facturée' &&
                              'bg-yellow-200'
                            }  w-full h-40 flex pt-2 flex-col  items-center`}
                          >
                            <p className="text-pink-500  text-center uppercase text-xs">
                              {contract.client}
                            </p>
                            <p className="text-mountain-600 text-[10px]">
                              {date.startHour}-{date.endHour}
                            </p>
                            <p className="text-black text-xs">
                              {contract.type}
                            </p>
                            <p className="text-black text-xs">
                              {contract.contractNumber}
                            </p>
                            {(contract.EstNonLieu === 'O' ||
                              contract.EstNonDemarre === 'O') && (
                              <p className="text-black text-xs">Annulé</p>
                            )}
                            {contract.status === 'Facturée' && (
                              <p className="text-black text-xs">facturée</p>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      )}

      {isClient && filter === 'hebdo' && (
        <div
          style={{ width: 'calc(100vw - 400px)' }}
          className="scrollbar-thin overflow-x-scroll bg-[#F7F9FC]"
        >
          <div
            style={{ width: 'calc(100vw - 400px)' }}
            className="flex font-semibold py-2 fixed bg-white"
          >
            <p className="w-1/12   text-center uppercase text-xs">Nom</p>
            <p className="w-1/12 text-center uppercase text-xs">Téléphone</p>
            <p className="w-1/12 text-center uppercase text-xs">Vehiculé</p>
            <p className="w-1/12 text-center uppercase text-xs">Ville</p>
            <p className="w-1/12 uppercase text-xs flex flex-col items-center">
              <span className="">Métier</span>
              <span className="">Jour/Nuit</span>
            </p>

            {days.map((day, idx) => {
              return (
                <div
                  key={idx}
                  className="w-1/12 flex items-center flex-col space-y-1"
                >
                  <p className="uppercase text-xs">{day.name}</p>
                  <p className="uppercase text-xs">{day.date}</p>
                </div>
              )
            })}
          </div>

          <div className="flex mt-10 flex-col font-semibold py-2">
            {contracts.map((contract, idx) => {
              return (
                <div className=" flex items-center" key={idx}>
                  <p className="hover:cursor-pointer w-1/12  px-2  items-center flex flex-col font-semibold uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 ">
                    <span>{contract.client}</span>
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      )}

      {!isClient && filter === 'mensuel' && (
        <div
          style={{ width: 'calc(100vw - 400px)' }}
          className="scrollbar-thin overflow-x-scroll bg-[#F7F9FC]"
        >
          <div className="flex font-semibold py-2 w-full whitespace-nowrap  bg-white">
            <p className="min-w-[160px] max-w-[160px]  text-center uppercase text-xs">
              Nom
            </p>
            <p className="min-w-[160px] max-w-[160px] text-center uppercase text-xs">
              Téléphone
            </p>
            <p className="min-w-[160px] max-w-[160px] text-center uppercase text-xs">
              Vehiculé
            </p>
            <p className="min-w-[160px] max-w-[160px] text-center uppercase text-xs">
              Ville
            </p>
            <p className="min-w-[160px] max-w-[160px] uppercase text-xs flex flex-col items-center">
              <span className="">Métier</span>
              <span className="">Jour/Nuit</span>
            </p>

            {daysOfMonth.map((day, idx) => {
              return (
                <div
                  key={idx}
                  className="min-w-[160px] bg-white max-w-[160px] flex items-center flex-col space-y-1"
                >
                  <p className="uppercase text-xs">{day.name}</p>
                  <p className="uppercase text-xs">{day.date}</p>
                </div>
              )
            })}
          </div>
          <div className="flex flex-col font-semibold">
            {contracts.map((contract, idx) => {
              return (
                <div className=" flex items-center" key={idx}>
                  <p className="hover:cursor-pointer min-w-[160px] max-w-[160px]  px-2  items-center flex flex-col font-semibold uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 ">
                    <span onClick={() => selectUser(contract.candidate)}>
                      {contract.firstname}
                    </span>
                    <span onClick={() => selectUser(contract.candidate)}>
                      {contract.lastname}
                    </span>
                    <span>
                      <button className="w-fit mx-auto mt-2 p-2 bg-purple-500 text-white rounded-md text-xs font-normal max-w-[160px]">
                        Envoyer le planning{' '}
                      </button>
                    </span>
                  </p>

                  <p className="min-w-[160px] max-w-[160px] text-center uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 ">
                    {contract.candidate?.phone}
                  </p>
                  <p className="min-w-[160px] max-w-[160px] text-center  uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 ">
                    {contract.candidate?.locomotions.join(' ')}
                  </p>
                  <p className="min-w-[160px] max-w-[160px] flex flex-col uppercase text-xs border-y border-r h-40 border-gray-200 pt-2 items-center">
                    <span>{contract.candidate?.postCode}</span>
                    <span>{contract.candidate?.town}</span>
                  </p>
                  <p className="min-w-[160px] max-w-[160px] text-center overflow-hidden uppercase text-xs border-y  border-r h-40 border-gray-200 pt-2 ">
                    {contract.qualification}
                  </p>
                  {contract.dates.map((date, idx2) => {
                    return (
                      <div
                        key={idx2}
                        className="flex bg-green-50 min-w-[160px] max-w-[160px]   flex-col items-center space-y-1 h-40 border-y border-r border-gray-200 pt-2"
                      >
                        {date.day && (
                          <div className="flex flex-col  items-center">
                            <p className="text-pink-500 text-center uppercase text-xs">
                              {contract.client}
                            </p>
                            <p className="text-mountain-600 text-[10px]">
                              {date.startHour}-{date.endHour}
                            </p>
                            <p className="text-black text-xs">
                              {contract.type}
                            </p>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default Planning
